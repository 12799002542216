import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {ProductPrice} from './ProductPrice/ProductPrice';
import {get, pick} from 'lodash';

export interface ProductPriceContainerProps extends ProvidedGlobalProps {
  className?: string;
}

@withGlobalProps
export class ProductPriceContainer extends React.Component<ProductPriceContainerProps> {
  private get pricePerUnitProps() {
    const {product} = this.props.globals;

    if (!product.pricePerUnitData) {
      return null;
    }

    return {
      pricePerUnit: this.prices.pricePerUnit,
      formattedPricePerUnit: this.prices.formattedPricePerUnit,
      baseMeasurementUnit: product.pricePerUnitData.baseMeasurementUnit,
      baseQuantity: product.pricePerUnitData.baseQuantity,
    };
  }

  private get prices(): {
    price: number;
    formattedPrice: string;
    comparePrice: number;
    formattedComparePrice: string;
    pricePerUnit?: number;
    formattedPricePerUnit?: string;
  } {
    const {product, selectedVariant} = this.props.globals;
    const pickedPrices = pick(
      selectedVariant?.subscriptionPlans?.[this.selectedPlan?.id] ||
        selectedVariant ||
        product.subscriptionPlans?.list.find((plan) => plan.id === this.selectedPlan?.id) ||
        product,
      ['price', 'formattedPrice', 'comparePrice', 'formattedComparePrice', 'formattedPricePerUnit', 'pricePerUnit']
    ) as any;

    if (this.hasDiscount) {
      return {
        price: pickedPrices.comparePrice,
        formattedPrice: pickedPrices.formattedComparePrice,
        comparePrice: pickedPrices.price,
        formattedComparePrice: pickedPrices.formattedPrice,
      };
    }
    return {...pickedPrices, comparePrice: null, formattedComparePrice: null};
  }

  private get selectedPlan() {
    return get(this.props.globals.userInputs.subscriptionPlan, ['0']);
  }

  private get hasDiscount() {
    return this.props.globals.product.discount?.value > 0;
  }

  public render(): JSX.Element {
    return (
      <ProductPrice
        {...this.prices}
        {...this.pricePerUnitProps}
        plan={this.selectedPlan}
        className={this.props.className}
      />
    );
  }
}
